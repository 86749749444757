// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-configuratore-lavaggi-js": () => import("./../../../src/pages/configuratore-lavaggi.js" /* webpackChunkName: "component---src-pages-configuratore-lavaggi-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavanderie-industriali-hera-basic-js": () => import("./../../../src/pages/lavanderie-industriali/hera-basic.js" /* webpackChunkName: "component---src-pages-lavanderie-industriali-hera-basic-js" */),
  "component---src-pages-lavanderie-industriali-hera-smart-js": () => import("./../../../src/pages/lavanderie-industriali/hera-smart.js" /* webpackChunkName: "component---src-pages-lavanderie-industriali-hera-smart-js" */),
  "component---src-pages-richiedi-demo-js": () => import("./../../../src/pages/richiedi-demo.js" /* webpackChunkName: "component---src-pages-richiedi-demo-js" */),
  "component---src-pages-smart-dashboard-js": () => import("./../../../src/pages/smart-dashboard.js" /* webpackChunkName: "component---src-pages-smart-dashboard-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

